import React from "react";
import styles from "./styles.module.scss";

const SensorGuideHeader = ({ instructions, isDesktop, loading, isHub }) => {
  const hubIsConnecting =
    "This can take up to 5 minutes. Please don't exit this page until the installation is complete.";
  const sensorIsConnecting = "Checking sensor connection...";

  return (
    <div>
      {/* leave padding-top as 10px on mobile so that the image can be displayed properly
        this is necessary for mobile, to scroll to the top of the container 
        without cropping the top of the image 
      */}
      <div
        style={{
          display: "flex",
          justifyContent: `${isDesktop ? "flex-start" : "center"}`,
          paddingTop: !isDesktop ? "10px" : "0px",
        }}
      >
        <img
          src={instructions?.image}
          alt={instructions?.alt}
          style={{
            maxWidth: `${instructions?.width}px`,
            maxHeight: `${instructions?.height}px` || "auto",
          }}
          className={styles.header_image}
        />
      </div>
      <div className={styles.title}>
        {loading ? "Connecting..." : instructions?.title}
      </div>
      {instructions?.description && (
        <div className={styles.description}>
          {loading && isHub
            ? hubIsConnecting
            : loading && !isHub
              ? sensorIsConnecting
              : instructions.description}
        </div>
      )}
    </div>
  );
};

export default SensorGuideHeader;
