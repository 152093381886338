import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { setSensorLocations } from "../../State/slices/sensorLocations-slice";
import styles from "./styles.module.scss";
import theme from "../../Styles/theme.scss";

const InstallSensorLocation = ({ sensor, showLocationError }) => {
  // Set the default options for sensor placement
  const dispatch = useDispatch();
  const sensorLocations = useSelector((state) => state.sensorLocations);
  const sensorLocation = sensorLocations?.[sensor?.name];

  // Keep track of when the user is entering a custom appliance for the plug sensor
  const otherSelected =
    sensorLocation &&
    (sensorLocation === "Other" ||
      !sensor?.locationOptions?.find(
        (location) => location.value === sensorLocation
      ));

  const saveSensorLocation = (location) => {
    const updatedLocations = { ...sensorLocations };
    // When the user deletes a custom location set it back to "Other"
    updatedLocations[sensor?.name] =
      location.value?.length === 0 ? "Other" : location.value;
    dispatch(setSensorLocations(updatedLocations));
  };

  return (
    <div>
      {!sensor?.locationRequired && (
        <div className={styles.location_placeholder}></div>
      )}
      {sensor?.locationRequired && (
        <>
          <div className={styles.select_appliacnce}>
            <label>
              Where was this sensor placed?
              <Select
                placeholder="Choose location..."
                options={sensor?.locationOptions}
                value={
                  (sensorLocation &&
                    (otherSelected
                      ? { value: "Other", label: "Other" }
                      : { value: sensorLocation, label: sensorLocation })) ??
                  null
                }
                onChange={saveSensorLocation}
                isSearchable={false}
                styles={{
                  control: (baseStyles, { isFocused }) => ({
                    ...baseStyles,
                    borderColor: isFocused
                      ? theme.primary3
                      : showLocationError
                        ? theme.risk4
                        : theme.neutral3,
                    marginTop: 4,
                    fontSize: theme.bodyMedium,
                    color: theme.neutral7,
                    borderRadius: 8,
                    ":hover": {
                      borderColor: showLocationError
                        ? theme.risk4
                        : theme.primary3,
                    },
                    boxShadow: "none",
                  }),
                  option: (styles, { isFocused }) => ({
                    ...styles,
                    fontSize: "16px",
                    backgroundColor: isFocused ? theme.neutral2 : null,
                    color: theme.neutral7,
                    // adjust background colour when mouse button is pressed
                    ":active": {
                      backgroundColor: theme.neutral3,
                    },
                  }),
                }}
                components={{
                  IndicatorSeparator: () => null, // Removes the "|" to the left of the drop-down arrow
                }}
              />
            </label>
          </div>
          {otherSelected && (
            <div className={styles.appliance_other}>
              <label>
                Other:
                <input
                  className={styles.input}
                  placeholder="Location"
                  onChange={(e) =>
                    saveSensorLocation({ value: e.target.value })
                  }
                  value={sensorLocation === "Other" ? "" : sensorLocation}
                />
              </label>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default InstallSensorLocation;
