/**
 * Determines the hardware status based on sensor data and installation status.
 * This is based on the new Hardware Monitoring endpoint which powers the SensorStatusTag and the Devices tab
 *
 * @param {Array<Object>} sensorsAndHub - An array of sensor objects, each containing sensor details.
 * @param {string} sensorsAndHub[].child_device_internal_id - Identifier for the sensor (e.g., "hub").
 * @param {string} sensorsAndHub[].current_status - Current status of the sensor (e.g., "up" or "down").
 * @param {boolean} isInstalled - Flag indicating if the hardware kit is installed.
 * @returns {Object} An object representing the hardware status.
 * @returns {string} returns.variant - The status variant (e.g., "error", "offline", "warning", "online").
 * @returns {string} returns.label - A label describing the hardware status.
 * @returns {boolean} returns.leftIcon - Whether an icon should be displayed on the left.
 *
 */

export const determineHardwareStatus = (sensorsAndHub, isInstalled) => {
  if (!isInstalled) {
    return {
      variant: "error",
      label: "Kit not installed",
      leftIcon: false,
    };
  }
  if (!sensorsAndHub || sensorsAndHub.length === 0)
    return {
      variant: "error",
      leftIcon: true,
      label: "Sensor status unavailable",
    };

  const sensorsExcludingHub = sensorsAndHub.filter(
    (sensor) => sensor.child_device_internal_id !== "hub"
  );

  const downSensorsCount = sensorsExcludingHub.filter(
    (sensor) => sensor.current_status !== "up"
  ).length;

  const allSensorsAndHubDown = sensorsAndHub.every(
    (sensor) => sensor.current_status === "down"
  );

  if (allSensorsAndHubDown) {
    return {
      variant: "offline",
      label: "Offline - Click to fix",
      leftIcon: true,
    };
  } else if (downSensorsCount >= 1) {
    return {
      variant: "warning",
      label: "Partially online",
      leftIcon: false,
    };
  } else {
    return { label: "Online", variant: "online", leftIcon: false };
  }
};
