import React from "react";
import styles from "./styles.module.scss";
import SensorSignal from "../SensorSignal";
import SensorSticker from "../SensorSticker";

const Sensor = ({ device, loading }) => {
  return (
    <div className={styles.sensor}>
      <div className={styles.sensor_left}>
        <SensorSticker label={device?.label} />
        <div className={styles.sensor_details}>
          <div className={styles.sensor_name}>
            {device?.displayName || device?.defaultName}
          </div>

          <div className={styles.sensor_signal}>
            <SensorSignal
              online={device?.current_status === "up"}
              loading={loading}
            />
          </div>
        </div>
      </div>

      <img src={device.img} className={styles.image} alt="sensor" />
    </div>
  );
};

export default Sensor;
