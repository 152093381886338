import React from "react";
import styles from "./styles.module.scss"; // Import the SCSS file
import classNames from "classnames";

const Skeleton = ({
  width = "100%",
  height = "20px",
  borderRadius = "4px",
  className,
}) => {
  return (
    <div
      className={classNames(styles.skeleton, className)}
      style={{
        width: width,
        height: height,
        borderRadius: borderRadius,
      }}
    ></div>
  );
};

export default Skeleton;
