import React, { useState, useEffect, useRef } from "react";
import styles from "./styles.module.scss";
import SensorGuideHeader from "../SensorGuideHeader";
import MobileGuideBody from "../MobileGuideBody";
import SensorMotionMethods from "../SensorMotionMethods";
import DesktopTips from "../DesktopTips";
import DesktopInstructions from "../DesktopInstructions";
import InstallationGoBackButton from "../../InstallationGoBackButton";
import { MOTION_METHOD_HEADERS } from "../../../Services/Constants";

const SensorGuide = ({
  isDesktop,
  instructions,
  isMotion,
  setShowGuideAnimation,
  isGuideVisible,
}) => {
  const [motionMethod, setMotionMethod] = useState(null);
  const motionInstructions = instructions?.guide?.instructions?.[motionMethod];
  const [showInstructions, setShowMotionInstructions] = useState(false);

  // always show the instructions if it is not a motion sensor
  // otherwise, only show motion instructions if a motion method was selected and 'next' button was clicked
  const showSensorGuideBody = !isMotion || (motionMethod && showInstructions);

  const headerToDisplay =
    isMotion && motionMethod
      ? MOTION_METHOD_HEADERS[motionMethod]
      : instructions?.guide?.header;

  /* Scroll to the top of the sensor guide when opened */
  // Create a ref to the top of the sensor guide container - only for mobile
  const containerRef = useRef(null);
  useEffect(() => {
    if (isGuideVisible && containerRef.current && !isDesktop) {
      containerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [isGuideVisible, isDesktop]);

  return (
    <div className={styles.sensor_guide_container}>
      {isDesktop ? (
        <div className={styles.desktop}>
          <div className={styles.desktop_top}>
            <div className={styles.desktop_left}>
              <SensorGuideHeader
                isDesktop={isDesktop}
                instructions={headerToDisplay}
              />
              {showSensorGuideBody && (
                <DesktopTips
                  instructions={
                    isMotion
                      ? motionInstructions
                      : instructions?.guide?.instructions
                  }
                />
              )}
            </div>
            <div className={styles.desktop_right}>
              {showSensorGuideBody && (
                <DesktopInstructions
                  instructions={
                    isMotion
                      ? motionInstructions
                      : instructions?.guide?.instructions
                  }
                />
              )}
              {isMotion && !showSensorGuideBody && (
                <SensorMotionMethods
                  options={instructions?.guide?.options}
                  motionMethod={motionMethod}
                  setMotionMethod={setMotionMethod}
                  setShowMotionInstructions={setShowMotionInstructions}
                  closeModal={() => {
                    setShowGuideAnimation(false);
                    setMotionMethod(null);
                  }}
                  isDesktop={isDesktop}
                />
              )}
              <p>
                If you’re having any issues please contact support on
                support@intelligentlilli.com
              </p>
            </div>
          </div>
          <div className={styles.desktop_bottom_buttons}>
            {isMotion && motionMethod && showSensorGuideBody && (
              <InstallationGoBackButton
                label="Install options"
                onClick={() => setMotionMethod(null)}
                marginTop="16px"
              />
            )}
          </div>
        </div>
      ) : (
        <div className={styles.mobile} ref={containerRef}>
          <SensorGuideHeader
            isDesktop={isDesktop}
            instructions={headerToDisplay}
          />
          <div className={styles.mobile_body}>
            {isMotion && !showSensorGuideBody && (
              <SensorMotionMethods
                options={instructions?.guide?.options}
                motionMethod={motionMethod}
                setMotionMethod={setMotionMethod}
                setShowMotionInstructions={setShowMotionInstructions}
                closeModal={() => {
                  setShowGuideAnimation(false);
                  setMotionMethod(null);
                }}
              />
            )}
            {showSensorGuideBody && (
              <MobileGuideBody
                motionMethod={motionMethod}
                goBacktoMotionInstallOptions={() => setMotionMethod(null)}
                isMotion={isMotion}
                setShowGuideAnimation={setShowGuideAnimation}
                setMotionMethod={setMotionMethod}
                instructions={
                  isMotion
                    ? motionInstructions
                    : instructions?.guide?.instructions
                }
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SensorGuide;
