import { getHardwareMonitoringStatus } from "@intelligentlilli/api-layer";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { determineHardwareStatus } from "../Utils/newDeviceMonitoring/determineHardwareStatus";

/**
 * Custom hook to fetch the hardware monitoring status of a specific device.
 *
 * @param {Object} params - Parameters for the hook.
 * @param {string} params.devicestring - Unique identifier for the device.
 * @param {string} params.serviceUserId - Identifier for the service user associated with the device.
 *
 * @returns {Object} - Returns an object containing the loading state, error message (if any), and data.
 * @property {boolean} loading - Indicates if the data fetching is in progress.
 * @property {string|null} error - Error message in case of a failure, or `null` if no error.
 * @property {Object|undefined} data - Fetched data for the hardware monitoring status, or `undefined` if not yet loaded.
 *
 * @example
 * const { loading, error, data } = useGetHardwareStatus({ devicestring: "device123", serviceUserId: "user456" });
 */
export const useGetHardwareStatus = ({ devicestring, serviceUserId }) => {
  // redux state
  const server = useSelector((state) => state.session.server);

  // local state
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const getHardwareStatus = useCallback(() => {
    if (!devicestring) return;
    setLoading(true);
    setError(null);
    getHardwareMonitoringStatus(server, serviceUserId, devicestring, "web")
      .then((res) => {
        if (res.ok && res.body) {
          setLoading(false);
          setData(res.body);
        } else {
          setLoading(false);
          setError(true);
          setData(null);
        }
      })
      .catch(() => {
        setLoading(false);
        setError(true);
        setData(null);
      });
  }, [devicestring, server, serviceUserId]);

  useEffect(() => {
    getHardwareStatus();
  }, [getHardwareStatus]);

  return {
    loading,
    error,
    data,
    getHardwareStatus,
    sensorStatus: determineHardwareStatus(data, !!devicestring),
  };
};

useGetHardwareStatus.propTypes = {
  devicestring: PropTypes.string.isRequired,
  serviceUserId: PropTypes.string.isRequired,
};
